import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"constants/fonts.ts\",\"import\":\"Montserrat\",\"arguments\":[{\"subsets\":[\"latin\"],\"preload\":true,\"variable\":\"--font-montserrat\",\"style\":[\"italic\",\"normal\"]}],\"variableName\":\"montserrat\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"constants/fonts.ts\",\"import\":\"DM_Mono\",\"arguments\":[{\"subsets\":[\"latin\"],\"preload\":true,\"variable\":\"--font-dm-mono\",\"style\":[\"italic\",\"normal\"],\"weight\":[\"300\",\"400\",\"500\"]}],\"variableName\":\"dm_mono\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"constants/fonts.ts\",\"import\":\"Jost\",\"arguments\":[{\"subsets\":[\"latin\"],\"preload\":true,\"variable\":\"--font-jost\",\"style\":[\"italic\",\"normal\"]}],\"variableName\":\"jost\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-toastify/dist/ReactToastify.css");
import(/* webpackMode: "eager" */ "/vercel/path0/styles/sass/copy.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/styles/sass/globals.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/styles/sass/headlessui.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/styles/sass/react-toastify.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/styles/sass/react-tooltip.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/styles/sass/rtl.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/styles/sass/productfruits.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/styles/sass/animations/wobble.scss")